import { useState, useEffect } from "react";
import moment from 'moment';

function HeaderBlock(props) {
    const [raceTime, startData] = useState([]);

    const raceTimeCalc = (startTime) => {
        const currentDate = moment();
        const duration = moment.duration(currentDate.diff(moment(startTime, 'YYYYMMDDHHmmss')))
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let formattedTime = "";


        if (hours < 3.5) {
            if (hours > 0) {
                formattedTime += `${hours}:`;
            }
            if (minutes > 0 || hours > 0) {
                formattedTime += `${minutes.toString().padStart(2, '0')}:`;
            }

            if (seconds >= 0 || (hours === 0 && minutes === 0)) {
                formattedTime += `${seconds.toString().padStart(2, '0')}`;
            }
            return ("Race time: "+formattedTime)
        }

        // return ("The race has now finished")
        return;
        
    }

    useEffect(() => {
        startData(raceTimeCalc(props.raceTime)); 
        const interval = setInterval(() => {
            startData(raceTimeCalc(props.raceTime)); 
          }, 1000); 

          return () => clearInterval(interval); 
      }, [props.raceTime]);


    return (
        <header>
            <img src={props.logo} alt={props.header} className="logo"/>
            <h1>{props.header}</h1>
            <div className="tenText"><h2><span className="brandColor">{props.tenkstart}</span></h2></div>
            <div className="tenText"><h3><span className="brandColor">{raceTime}</span></h3></div>
        </header>
    );
}

export default HeaderBlock;