import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { initializeApp } from "firebase/app";

import './assets/webcssall.css'

import ResultsTable from './components/resultstable'
import reportWebVitals from './reportWebVitals';
import EditsTable from './components/editsstable'
import LoginUser from './components/login'
import UploadRecords from './components/uploadRecords'

const firebaseConfig = {
  apiKey: "AIzaSyBquTZItciS7iCeSlAc1n2QDtf65E6uPJk",
  authDomain: "dirty-dozen-auth.firebaseapp.com",
  projectId: "dirty-dozen-auth",
  storageBucket: "dirty-dozen-auth.appspot.com",
  messagingSenderId: "790690878675",
  appId: "1:790690878675:web:3f9ac9009ac6e13738f026"
};


const app = initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<ResultsTable />} />
        <Route path="/login" element={<LoginUser />} />
        <Route path="/admin" element={<EditsTable />} />
        <Route path="/import" element={<UploadRecords />} />
      </Routes>
    </BrowserRouter>
  </div>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
